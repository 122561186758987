.news {
  display: flex;
  flex-wrap: wrap;
  gap: var(--element-gap);
}

.newsCard {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: calc((100% - var(--element-gap)) / 2);

  @media (max-width: 768px) {
    width: auto;
  }

  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: var(--color-moow-magenta);
  }

  .cover {
    width: 100%;
    height: 300px;
    @media (max-width: 768px) {
      height: 200px;
    }

    border-radius: 16px;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .headline {

    display: flex;
    gap: 16px;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }

    .title {
      flex: 1 1;
    }
    .date {}

  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .link {
    color: var(--color-moow-magenta);
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: #C10B7A;
    }

  }

}

.modal {

  display: flex;
  flex-direction: column;
  gap: 24px;

  .cover {
    width: 100%;
    height: 300px;
    @media (max-width: 768px) {
      height: 200px;
    }

    border-radius: 16px;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .headline {

    display: flex;
    gap: 16px;
    flex-direction: column-reverse;

    .title {
      font-size: 24px;
    }
    .date {}

  }
  
}

.wrapper {
  width: calc(100vw - 40px);
}
.slide {
  width: calc(100% - 2px) !important;
  padding: 0 2px;
}