.solids {
  display: flex;
  flex-wrap: wrap;
  gap: var(--element-gap);
}

.solid {
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: calc((100% - var(--element-gap)*3) / 4);
  @media (max-width: 768px) {
    width: auto;
  }

  padding: 16px;
  border-radius: 16px;

  /* Card Shadow */
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.1);
  }

  .title {
    font-weight: bold;
    font-size: 24px;

    display: flex;
    gap: 8px;
    align-items: center;
  }

}

.wrapper {
  width: calc(100vw - 40px);
}
.slide {
  width: calc(100% - 24px) !important;
  padding: 4px 12px 16px 12px;
}