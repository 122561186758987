.school {
  background-size: 75%;
  @media (max-width: 768px) {
    background-size: 100%;
  }
  @media (max-width: 425px) {
    background-size: 200%;
  }

  background-repeat: repeat-x;
  background-position: bottom;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }



  .background {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;


  }

  .line {
    margin-top: var(--element-gap);
    background-color: var(--color-moow-light-gray);
    border-bottom: 1px solid var(--color-moow-text);
  }

}

.badge {
  display: block;
  height: 40px;
}