.cover {
  position: relative;
  height: calc(100vh - 80px);
  min-height: 600px;
  max-height: 900px;
  background-color: var(--color-moow-yellow);

  background-size: 75%;
  background-repeat: repeat-x;
  background-position: bottom;
  @media (max-width: 768px) {
    background-size: 100%;
  }
  @media (max-width: 425px) {
    background-size: 200%;
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    text-align: center;
  }

  .suptitle {
    font-weight: bold;
    z-index: 10;
  }

  .subtitle {
    margin-top: var(--element-gap);
    font-weight: bold;
    z-index: 10;
  }

  .title {
    margin-top: 32px;
    font: var(--font-title);
    font-size: 32px;
    font-weight: bold;
    color: var(--color-moow-magenta);
    text-transform: uppercase;
    word-break: break-word;
    hyphens: auto;
    z-index: 10;
    text-shadow: 0 0 16px rgba(235, 231, 23, 0.5);
  }

  .space {
    flex: 1 1;
    position: relative;

    .image {
      width: auto;
      height: 100vh;
      position: absolute;
      left: 0;
      right: 0;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 5;
    }
  }

  .footer {
    padding: 0 20px;
    margin-bottom: 64px;
    z-index: 10;

  }

  .wave {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 115px;
    z-index: 1;
    img {
      width: 100%;
      height: 100%;
      //object-fit: fill;
    }
  }

}