.header {
  background-color: var(--color-moow-yellow);
  padding: 24px 20px;

  .wrapper {
    display: flex;
    align-items: center;
    .left {
      //flex: 1 1;
    }
    .center {
      .logo {
        width: 176px;
        height: 24px;
      }
    }
    .right {
      flex: 1 1;
      text-align: right;
    }
  }

}

.flag {
  display: inline-block;
  width: 24px;
  height: 16px;
  //border: 1px solid var(--color-border);
}
.flagOnButton {
  position: relative;
  top: 2px;
}