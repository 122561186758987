.wrapper {

  //display: flex;
  //flex-direction: column;
  //gap: var(--element-gap);

  .payment {
    display: flex;
    flex-direction: row;
    gap: var(--element-gap);
    align-items: center;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    .text {
      flex: 1 1;
    }
    .image {
      width: 160px;
      height: 40px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .button {
    width: auto;
    margin-top: var(--element-gap);
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}