.blocks {
  display: flex;
  gap: var(--element-gap);
  flex-direction: row;
  @media (max-width: 640px) {
    flex-direction: column;
  }

  .card {
    flex: 1 1;
    @media (max-width: 640px) {
      max-width: 100% !important;
    }

    .flag {
      //width: 32px;
      height: 18px;
      border-radius: 2px;
    }
    .contacts {
      display: flex;
      flex-direction: column;
      gap: 8px;

      a {
        color: var(--color-moow-text);
      }

    }

  }

}