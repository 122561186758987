.cards {
  display: flex;
  flex-wrap: wrap;
  gap: var(--element-gap);
}

.card {
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: calc((100% - var(--element-gap)*3) / 4);
  @media (max-width: 960px) {
    width: calc((100% - var(--element-gap)) / 2);
  }
  @media (max-width: 768px) {
    width: 100%;
  }

  font-size: 24px;
  font-weight: bold;
  word-break: break-word;

  padding: 16px;

  border: 1px solid var(--color-moow-light-gray);
  border-radius: 16px;

  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: var(--color-moow-magenta);
  }

  &.active {
    background-color: var(--color-moow-magenta);
    border-color: var(--color-moow-magenta);
    color: #FFFFFF;
  }

}

.wrapper {
  width: calc(100vw - 40px);
}
.slide {
  width: calc(100% - 2px) !important;
  padding: 0 2px;
}