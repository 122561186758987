.chess {
  display: flex;
  flex-direction: column;
  gap: var(--element-gap);
}

.square {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--element-gap);
  align-items: center;

  &:nth-child(even) {
    flex-direction: row;
  }

  .image {
    flex: 1 1;
    height: 260px;
    @media (max-width: 768px) {
      height: 210px;
    }
    @media (max-width: 425px) {
      height: 160px;
    }
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

  }
  .wrapper {
    flex: 1 1;
    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .item {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        .icon {
          width: 32px;
          height: 32px;
        }
        .text {
          margin-top: 4px;
        }
      }
    }
  }

}

.card {
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: calc((100% - var(--element-gap)*2) / 3 - 34px);

  padding: 16px;
  border: 1px solid var(--color-moow-light-gray);
  border-radius: 16px;

  .title {
    font-weight: bold;
  }

}