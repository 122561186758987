.highlights {
  display: flex;
  flex-wrap: wrap;
  gap: var(--element-gap);
}

.highlight {
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: calc((100% - var(--element-gap)*2) / 3 - 34px);
  @media (max-width: 768px) {
    width: auto;
  }

  padding: 16px;
  border-radius: 16px;

  transition: all 0.3s ease-in-out;

  text-align: center;

  .image {
    height: 260px;
    @media (max-width: 768px) {
      height: 200px;
    }
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 24px;
  }


  &:hover {

  }

}

.wrapper {
  width: calc(100vw - 40px);
}
.slide {
  //height: 370px;
}