.slick-slide {
    /*margin-right: 20px;*/
}

.dots {
    display: flex !important;
    justify-content: center;
    gap: 8px;
    width: 100%;
    padding: 0;
    margin: 16px 0 0 0;

    list-style: none;

    text-align: center;
}

.dots li {
    display: inline-block;
    width: 32px;
    height: 32px;
    /*margin: 0 4px;*/
    border-radius: 16px;
    text-align: center;
    line-height: 32px;
    border: 1px solid var(--color-moow-magenta);
    color: var(--color-moow-magenta);
    font-weight: bold;
}

.dots li.slick-active {
    background-color: var(--color-moow-magenta);
    color: var(--color-moow-white);
}