.image {
  width: 234px;
  height: 32px;
}

.wave {
  width: 100%;
  height: 115px;
  img {
    width: 100%;
    height: 100%;
    //object-fit: fill;
  }
}

.yellow {
  background-color: var(--color-moow-yellow);
}