.page {
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--block-gap);
  padding: var(--block-gap) 0;
}

.formBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--block-gap) 20px 0 20px;
  background-color: var(--color-moow-light-gray);
  //text-align: center;

  .form {
    width: 100%;
    max-width: 375px;
    margin-top: var(--element-gap);
    margin-bottom: var(--block-gap);
  }

}

.line {
  background-color: var(--color-moow-light-gray);
  border-bottom: 1px solid var(--color-moow-text);
}