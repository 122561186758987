@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

:root {

  --font-family-body: 'Open Sans', sans-serif;
  --font-family-title: 'Dela Gothic One', sans-serif;

  --font-body: 16px var(--font-family-body);
  --font-title: 24px var(--font-family-title);
  --font-caption: 12px var(--font-family-body);

  --color-moow-text: #1F2A44;
  --color-moow-yellow: #EBE717;
  --color-moow-magenta: #FF40B4;
  --color-moow-light-gray: #C5CFDA;
  --color-moow-warning: #EBC917;
  --color-moow-success: #A6E950;
  --color-moow-danger: #E95050;

  --block-gap: 80px;
  --element-gap: 40px;
}

@media (max-width: 768px) {
  :root {
    --block-gap: 40px;
    --element-gap: 24px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-width: 320px;
  margin: 0;
  background-color: var(--color-moow-white);
  font: var(--font-body);
  font-stretch: normal;
  font-weight: normal;
  color: var(--color-moow-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: var(--font-family-title);
}

h2 {
  font-family: var(--font-family-title);
  font-size: 24px;
  color: var(--color-moow-text);
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

.bold {
  font-weight: bold;
}

.col12 {
  width: 100%;
  max-width: 1160px;
  margin: auto;
}

.col8 {
  width: 100%;
  max-width: 760px;
}

.col6 {
  width: 100%;
  max-width: 560px;
}

.col4 {
  width: 100%;
  max-width: 360px;
}

.content {
  padding: 0 20px;
}

.caption {
  font-size: 12px;
}

.StrapiRichBlocks {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.StrapiRichBlocks ul {
  margin-block-start: unset;
  margin-block-end: unset;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.StrapiRichBlocks h6 {
  font-size: 16px;
  margin-block-start: unset;
  margin-block-end: unset;
}

.StrapiRichBlocks h5 {
  font-size: 18px;
  margin-block-start: unset;
  margin-block-end: unset;
}

.StrapiRichBlocks h4 {
  font-size: 20px;
  margin-block-start: unset;
  margin-block-end: unset;
}

.StrapiRichBlocks h3 {
  font-size: 22px;
  margin-block-start: unset;
  margin-block-end: unset;
}

.StrapiRichBlocks h2 {
  font-size: 24px;
  margin-block-start: unset;
  margin-block-end: unset;
}

.StrapiRichBlocks h1 {
  font-size: 32px;
  margin-block-start: unset;
  margin-block-end: unset;
}

.block {
  display: block;
  width: 100%;
}


.ant-popover {
  max-width: 400px;
  padding: 0 20px;
}