.fares {
  display: flex;
  gap: var(--element-gap);

  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .fare {

    display: flex;
    flex-direction: column;

    .title {

      font: var(--font-title);
      font-size: 24px;
      text-transform: uppercase;
    }

    .cards {

      flex: 1 1;

      display: flex;
      gap: var(--element-gap);
      flex-direction: row;
      @media (max-width: 640px) {
        flex-direction: column;
      }


      padding-top: 24px;
      margin-top: 24px;
      border-top: 1px solid var(--color-moow-light-gray);

      .card {

        flex: 1 1;
        @media (max-width: 640px) {
          max-width: 100% !important;
        }

        //width: calc((100% - var(--element-gap)*2) / 3);
        //@media (max-width: 960px) {
        //  width: calc((100% - var(--element-gap)) / 2);
        //}
        //@media (max-width: 768px) {
        //  width: 100%;
        //}


      }
    }

  }

}