.cover {
  background-color: var(--color-moow-yellow);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--element-gap);
    padding: var(--element-gap) 20px;
    text-align: center;
  }

  .suptitle {
    font-weight: bold;
  }

  .title {
    font: var(--font-title);
    font-size: 32px;
    font-weight: bold;
    color: var(--color-moow-magenta);
    text-transform: uppercase;
    word-break: break-word;
    hyphens: auto;
    text-shadow: 0 0 16px rgba(235, 231, 23, 0.5);
  }

  .subtitle {
    font-weight: bold;
  }

  .footer {
    //margin: var(--element-gap) auto;
  }

  .wave {
    width: 100%;
    height: 115px;
    img {
      width: 100%;
      height: 100%;
      //object-fit: fill;
    }
  }

}