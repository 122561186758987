.app {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  .icon {
  }
  .text {
    flex: 1 1;
    .name {
      font-weight: bold;
    }
    .description {}
  }
}