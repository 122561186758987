.footer {
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--color-moow-light-gray);
  align-items: center;

  .social {
    display: flex;
    gap: 40px;
    @media (max-width: 768px) {
      gap: 16px;
    }
    @media (max-width: 375px) {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .links {
    display: flex;
    gap: 32px;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 16px;
      text-align: center;
    }

    a {
      color: var(--color-moow-text);
    }

  }

  .caption {
    font-size: 12px;
    text-align: center;
  }

}