.image {
  width: 100%;
  max-width: 1160px;
  border-radius: 16px;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    height: 300px;
  }
  @media (max-width: 425px) {
    height: 200px;
  }

  &.default {
    height: 400px;
    @media (max-width: 768px) {
      height: 300px;
    }
    @media (max-width: 425px) {
      height: 200px;
    }
  }

  &.tall {
    height: 600px;
    @media (max-width: 768px) {
      height: 450px;
    }
    @media (max-width: 425px) {
      height: 300px;
    }
  }


}