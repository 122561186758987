.stories {
  display: flex;
  flex-wrap: wrap;
  gap: var(--element-gap);
}

.story {
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: calc((100% - var(--element-gap)) / 2);
  border: 1px solid var(--color-moow-light-gray);
  border-radius: 16px;

  @media (max-width: 768px) {
    width: auto;
  }

  padding: 16px;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: var(--color-moow-magenta);
  }

  .image {
    width: 100%;
    height: 300px;
    @media (max-width: 768px) {
      height: 200px;
    }

    border-radius: 16px;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .description {}

}

.wrapper {
  width: calc(100vw - 40px);
}
.slide {
  width: calc(100% - 2px) !important;
  padding: 0 2px;
}