.wrapper {

  display: block;
  position: relative;

  .dot {
    position: absolute;
    width: 32px;
    height: 32px;
    cursor: pointer;
    opacity: 1;
    &.disabled {
      cursor: auto;
      opacity: 0.5;
      .radius {
        animation: none;
      }
    }
    //background-color: red;
    .radius {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 4px;
      left: 4px;
      border-radius: 16px;
      border: 1px solid var(--color-moow-magenta);
      animation: 1s infinite alternate pulsating;
    }
    .point {
      position: absolute;
      top: 12px;
      left: 12px;
      width: 8px;
      height: 8px;
      background-color: var(--color-moow-magenta);
      border-radius: 4px;
    }
  }

}

//.popover {
//  max-width: 335px;
//}

@keyframes pulsating {
  from {
    width: 8px;
    height: 8px;
    top: 12px;
    left: 12px;
  }

  to {
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
  }
}