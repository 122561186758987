.sheet {
}

.sheetWrapper {
  padding: 0 20px 20px 20px;
  position: relative;
  z-index: 1100;

  .title {
    font-weight: 500;
    margin-bottom: 16px;
  }

}